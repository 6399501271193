/* src/styles/Footer.css */
.footer-section {
  background-color: #333;
  color: #fff;
  padding: 40px 20px;
  font-family: "Open Sans", sans-serif;
}

.footer-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.footer-logo-social {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 20px;
}

.footer-logo {
  width: 150px;
  margin-bottom: 20px;
}

.footer-social {
  display: flex;
  gap: 10px;
}

.social-logo {
  width: 30px;
  height: 30px;
}

.footer-links {
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
}

.footer-column {
  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: 200px;
}

.footer-column h3 {
  font-size: 1.5rem;
  margin-bottom: 15px;
}

.footer-column ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer-column ul li {
  margin-bottom: 10px;
}

.footer-column ul li a {
  color: #fff;
  text-decoration: none;
  font-size: 1rem;
}

.footer-column ul li a:hover {
  text-decoration: underline;
}

.footer-bottom {
  border-top: 1px solid #444;
  padding-top: 20px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer-bottom p {
  margin: 0;
  margin-bottom: 10px;
}

.footer-bottom ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  gap: 20px;
}

.footer-bottom ul li a {
  color: #fff;
  text-decoration: none;
  font-size: 1rem;
}

.footer-bottom ul li a:hover {
  text-decoration: underline;
}
.svg-container svg {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
  }
