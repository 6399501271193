/* Fondo gris translúcido */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  /* Contenido del modal */
  .modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    max-width: 800px;
    width: 100%;
    max-height: 90vh;
    overflow-y: auto;
    position: relative;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  /* Botón de cerrar */
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
  }
  
  .checkbox-container {
    margin-bottom: 20px;
  }
  
  /* Para que el modal se vea bien en móviles */
  @media (max-width: 1024px)  {
    .modal-content {
      max-width: 90%;
      max-height: 80vh;
    }
  }
  