/* src/styles/Hero.css */
.hero-container {
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #1b1b1b;
  overflow: hidden;
  font-family: "Open Sans", sans-serif;
  clip-path: polygon(0 0, 100% 0, 100% 95%, 50% 100%, 0 95%);
  margin-bottom: 100px;
  margin-top: -100px;
}

.gif-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #d2e9ffde;
  z-index: 0;
}
.hero-container::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100px; /* Ajusta la altura del degradado */
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, #d2e9ff 100%);
  z-index: 2;
}

.hero-content {
  text-align: center;
  z-index: 1;
}

.hero-content h1 {
  font-family: "Caveat", cursive;;
  font-size: 100px;
  margin-bottom: 1rem;
  opacity: 0;
  transform: translateY(20px);
  animation: fadeInUp 0.5s ease-in-out forwards;

}

.hero-content p {
  font-size: 1.5rem;
  margin-bottom: 2rem;
  opacity: 0;
  transform: translateY(20px);
  animation: fadeInUp 0.5s ease-in-out forwards;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.hero-button {
  padding: 1rem 2rem;
  border: none;
  background-color: #ff6b6b;
  color: white;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  font-family: "Open Sans", sans-serif;
}

.hero-button:hover {
  background-color: #ff4d4d;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

@media (max-width: 480px) {
  .hero-content h1 {
    font-size: 40px; /* Reducir aún más el tamaño del título para pantallas pequeñas */
  }

  .hero-content p {
    font-size: 1rem; /* Ajustar el tamaño del texto */
  }

  .hero-button {
    font-size: 0.75rem; /* Ajustar el tamaño del botón */
    padding: 0.5rem 1rem;
  }
}