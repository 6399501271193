.not-found-container {
    text-align: center;
    padding: 50px;
    margin-top: 150px;
    margin-bottom: 250px;
  }
  
  .not-found-container h1 {
    font-size: 48px;
    color: #333;
  }
  
  .not-found-container p {
    font-size: 18px;
    margin: 20px 0;
    color: #666;
  }
  
  .home-link {
    color: #007bff;
    text-decoration: none;
    font-size: 20px;
  }
  
  .home-link:hover {
    text-decoration: underline;
  }
  