.support-form-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #ffffff;
  margin-top: 150px;
  margin-bottom: 150px;
  transform: translateY(20px);
  animation: fadeIn 1s ease-out forwards;
  }
  
  .support-form h2 {
    text-align: center;
    font-size: 24px;
    margin-bottom: 20px;
    color: #333;
  }
  
  .message {
    text-align: center;
    color: green;
    margin-bottom: 20px;
  }
  
  .support-form .form-group {
    margin-bottom: 20px;
  }
  
  .support-form label {
    display: block;
    font-size: 16px;
    margin-bottom: 8px;
    color: #555;
  }
  
  .support-form .form-input,
  .support-form .form-textarea {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
    outline: none;
  }
  
  .support-form .form-input:focus,
  .support-form .form-textarea:focus {
    border-color: #007bff;
  }
  
  .support-form .form-textarea {
    height: 100px;
    resize: none;
  }
  

  