/* Container for the profile page */
.profile-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-top: 150px;
    margin-bottom: 80px;
    transform: translateY(20px);
    animation: fadeIn 1s ease-out forwards;
  }
  
  h2 {
    text-align: center;
    color: #333;
  }
  
  .profile-section {
    margin-bottom: 15px;
  }
  
  .profile-section label {
    display: block;
    font-weight: bold;
    color: #555;
  }
  
  .profile-section p, .profile-section input {
    margin: 5px 0;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 5px;
    width: 100%;
  }
  

  .profile-actions button {
    margin-right: 10px;
    background-color: #28a745;
    margin-top: 10px;
    padding: 10px 20px;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  .profile-spinner-container {
    min-height: 400px; /* Ajusta este valor según el tamaño esperado */
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-bottom: 700px;
  }
  
  .loading-spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-top: 4px solid #3498db;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  .profile-actions button:hover {
    background-color: #218838;
  }
  
  .edit-button {
    background-color: #ffc107;
    margin-top: 10px;
    padding: 10px 20px;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .edit-button:hover {
    background-color: #e0a800;
  }
  
  .save-button {
    background-color: #28a745;
    margin-top: 10px;
    padding: 10px 20px;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin: 5px;
  }
  
  .save-button:hover {
    background-color: #218838;
  }
  
  .cancel-button {
    background-color: #dc3545;
    margin-top: 10px;
    padding: 10px 20px;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .cancel-button:hover {
    background-color: #c82333;
  }
  