/* src/styles/FinalSection.css */
.final-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 100px 20px;
    color: white;
    text-align: center;
    font-family: "Open Sans", sans-serif;
    background-image: url('../effects/graphics/circle.svg'); /* Añade la ruta del SVG */
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    margin-bottom: 100px;
  }
  .text-red {
    color: rgb(0, 0, 0)!important;
    background-image: url('../effects/highlights/blue_highlight_01.svg'); /* Añade la ruta del SVG */
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }

  .final-section h2 {
    font-size: 60px;
    margin-bottom: 20px;
    line-height: 1.2;
    font-family: "Caveat", cursive;
    margin-top: 0px;
    color: rgb(0, 0, 0);
  }
  
  
  .contact-button {
    padding: 20px 20px;
    border: none;
    border-radius: 100px;
    background: #1bb6f9;
    color: white;
    cursor: pointer;
    transition: background 0.3s, color 0.3s;
    font-size: 1.2rem;
    font-weight: bold;
    text-decoration: none;
  }
  .contact-button:hover {
    background: #0a80b2;
    color: white;
  }
  @media (max-width: 1024px)  {
    .final-section {
      padding: 50px 10px;
      background-size: 150%; /* Reducir el tamaño del SVG en la versión móvil */
      margin-bottom: 50px;
    }
  
    .final-section h2 {
      font-size: 40px;
      margin-bottom: 15px;
    }
  
    .text-red {
      background-size: 100%; /* Ajustar el tamaño del highlight SVG */
    }
  
    .contact-button {
      padding: 15px 20px;
      font-size: 1rem;
    }
  }
  