.pricing {
  padding: 50px 20px;
  font-family: 'Open Sans', sans-serif;
  text-align: center;
  opacity: 0;
  transform: translateY(20px);
  margin-top: 100px;
  animation: fadeIn 1s ease-out forwards;
  background-image: url('../effects/graphics/corner_4.svg');
  background-repeat: no-repeat;
  background-position: top left;
  background-size: 15%;
}

.pricing-header {
  font-size: 80px;
  margin-bottom: 1px;
  color: #333;
  font-family: "Caveat", cursive;
  background-image: url('../effects/highlights/yellow_highlight_03.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  text-align: center;
}

.pricing-table {
  display: flex;
  justify-content: space-around;
  gap: 20px;
  flex-wrap: wrap;
}

.pricing-plan {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 30px 20px;
  flex: 1;
  min-width: 300px;
  max-width: 400px;
  transition: transform 0.3s ease;
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 50px;
}



.pricing-plan h2 {
  font-size: 1.8rem;
  margin-bottom: 20px;
  color: #333;
  font-family: "Caveat", cursive;
  text-align: center;
}

.pricing-plan .price {
  font-size: 2rem;
  color: #e67e22;
  margin-bottom: 10px;
}
.pricing-plan .price .discounted {
  font-size: 1rem;
  color: #999;
  text-decoration: line-through;
  margin-left: 10px;
}

.pricing-plan .price .per-user {
  font-size: 0.8rem;
  color: #3498db;
  margin-left: 5px;
}
.pricing-plan .price .per-user-red {
  font-size: 0.8rem;
  color: #c22a2a;
  margin-left: 5px;
}


.pricing-plan ul {
  list-style: none;
  padding: 0;
  text-align: left;
  margin-bottom: 30px;
}

.pricing-plan ul li {
  margin-bottom: 15px;
  font-size: 1rem;
  display: flex;
  align-items: center;
}

.pricing-plan ul li img {
  margin-right: 10px;
  width: 20px;
  height: auto;
}

.select-plan {
  background-color: #e67e22;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: auto;
  text-decoration: none; 
}

.select-plan:hover {
  background-color: #d35400;
}
.basic h2{
  background-image: url('../effects/highlights/blue_highlight_01.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.premium h2{
  background-image: url('../effects/highlights/green_highlight_03.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.custom h2{
  background-image: url('../effects/highlights/red_highlight_01.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
/* Contenedor del Switch */
.switch-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
}

/* Estilos del Switch */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  margin: 0 15px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #e67e22;
}

input:checked + .slider:before {
  transform: translateX(26px);
}

.switch-labels {
  display: flex;
  align-items: center;
  font-size: 1rem;
  color: #333;
}

.switch-labels span {
  margin: 0 15px;
}
@media (max-width: 1024px)  {
  .pricing {
    padding: 40px 15px;
    margin-top: 120px;
    background-size: 25%; /* Ajustar el tamaño del gráfico SVG */
  }

  .pricing-header {
    font-size: 50px;
    background-size: 90%; /* Ajustar el tamaño del highlight */
  }

  .pricing-table {
    flex-direction: column; /* Cambiar a columnas para pantallas más pequeñas */
  }

  .pricing-plan {
    min-width: 100%; /* Asegurarse de que los planes ocupen todo el ancho disponible */
    margin-bottom: 30px;
    margin-top: 30px;
  }

  .pricing-plan h2 {
    font-size: 1.5rem;
  }

  .pricing-plan .price {
    font-size: 1.6rem;
  }

  .pricing-plan ul li {
    font-size: 0.9rem;
  }

  .select-plan {
    font-size: 0.9rem;
  }

  .switch {
    width: 50px;
    height: 28px;
  }

  .slider:before {
    height: 22px;
    width: 22px;
  }

  .switch-labels span {
    font-size: 0.9rem;
  }
}

@media (max-width: 480px) {
  .pricing-header {
    font-size: 40px;
  }

  .pricing-plan {
    min-width: 100%; /* Ocupar todo el ancho en pantallas móviles */
    margin-bottom: 20px;
  }

  .pricing-plan h2 {
    font-size: 1.2rem;
  }

  .pricing-plan .price {
    font-size: 1.4rem;
  }

  .select-plan {
    font-size: 0.8rem;
  }

  .switch {
    width: 40px;
    height: 24px;
  }

  .slider:before {
    height: 20px;
    width: 20px;
  }

  .switch-labels span {
    font-size: 0.8rem;
  }
  
}
