.checkout-container {
    max-width: 500px;
    margin: 0 auto;
    padding: 2rem;
    background-color: #fff;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    margin-top: 150px;
    margin-bottom: 150px;
  }
  
  .checkout-title {
    font-size: 1.5rem;
    font-weight: bold;
    text-align: center;
    margin-bottom: 1.5rem;
    color: #333;
  }
  
  .checkout-form {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }
  
  .card-details {
    display: flex;
    flex-direction: column;
  }
  
  label {
    font-size: 1rem;
    margin-bottom: 0.5rem;
    color: #555;
  }
  
  .card-element {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #f9f9f9;
  }
  
  .checkout-button {
    padding: 12px;
    font-size: 1rem;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .checkout-button:disabled {
    background-color: #ccc;
  }
  
  .checkout-button:hover:not(:disabled) {
    background-color: #45a049;
  }
  
  .error-message {
    color: red;
    font-size: 0.9rem;
    text-align: center;
  }
  