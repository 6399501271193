.reviews-section {
  padding: 60px 20px;
  text-align: center;
  font-family: "Open Sans", sans-serif;
  margin-top: 150px;
  margin-bottom: 150px;
  color: #1b1b1b;
  position: relative;
}

.reviews-header {
  position: absolute;
  top: 10px;
  right: 20px;
  display: flex;
  gap: 10px;
}

.arrow {
  background-color: #333;
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  border-radius: 50%;
  font-size: 1.5rem;
}

.arrow:hover {
  background-color: #555;
}

.reviews-container {
  display: flex;
  justify-content: center;
  gap: 20px;
  transition: transform 0.5s ease-in-out;
}

.review {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: rgba(255, 255, 255, 0.222);
  padding: 40px;
  border-radius: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 500px;
  min-width: 300px;
}

.review p {
  font-size: 1.2rem;
  color: #555;
  margin-bottom: 1rem;
}

.review h4 {
  font-size: 1.5rem;
  color: #333;
}

@media (max-width: 1024px)  {
  .reviews-section {
    margin-top: 120px;
    margin-bottom: 80px;
  }

  .reviews-header {
    top: 5px;
    right: 10px;
  }

  .reviews-container {
    flex-direction: column;
    gap: 10px;
  }

  .review {
    padding: 20px;
    max-width: 100%;
  }

  .review p {
    font-size: 1rem;
  }

  .review h4 {
    font-size: 1.2rem;
  }
}
