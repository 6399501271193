.back-to-top {
    position: fixed;
    bottom: 30px;
    left: 30px; 
    width: 50px;
    height: 50px;
    background-color: #3c3c3c; 
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    cursor: pointer;
    transition: background-color 0.3s;
    opacity: 0;
    visibility: hidden;
    z-index: 1000;
    font-size: 20px;
    font-family: Calibri, sans-serif;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .back-to-top.visible {
    opacity: 1;
    visibility: visible;
  }
  
  .back-to-top:hover {
    background-color: #6c6c6c;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }