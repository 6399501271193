/* src/styles/InteractiveSection.css */
.interactive-section {
    padding: 60px 20px;
    background-color: #d2e9ff;
    text-align: center;
    font-family: "Open Sans", sans-serif;
    margin-top: 150px;
    margin-bottom: 200px;
    background-image: url('../effects/graphics/fireworks_02.svg'); /* Añade la ruta del SVG */
    background-repeat: no-repeat;
    background-position: top right;
    background-size: 15%;
  }
  
  .interactive-section h2 {
    font-size: 50px;
    margin-bottom: 2rem;
    color: #333;
    background-image: url('../effects/highlights/green_highlight_03.svg'); /* Añade la ruta del SVG */
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    font-family: "Caveat", cursive;
  }
  
  .interactive-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 40px;
    
  }
  
  .interactive-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 300px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    
  }
  
  .interactive-item:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  .interactive-image {
    width: 100%;
    border-radius: 10px;
    margin-bottom: 1rem;
  }
  
  .interactive-text h3 {
    font-size: 1.5rem;
    color: #333;
    margin-bottom: 1rem;
  }
  
  .interactive-text p {
    font-size: 1rem;
    color: #555;
  }
  .interactive-icon {
    width: 80px;
    height: 80px;
    margin-bottom: 1rem;
    fill: #333; /* Cambia el color del ícono según tu preferencia */
  }
  
  .interactive-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 300px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .interactive-item:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  @media (max-width: 1024px)  {
  .interactive-section {
    padding: 30px 10px;
    margin-top: 100px;
    margin-bottom: 100px;
    background-size: 25%; /* Ajuste para el tamaño del SVG */
  }

  .interactive-section h2 {
    font-size: 35px; /* Reducir el tamaño del texto en móviles */
    background-size: 50%; /* Ajustar el fondo para móviles */
  }

  .interactive-content {
    flex-direction: column; /* Alinear los ítems en columna en lugar de fila */
    gap: 20px; /* Reducir el espacio entre ítems */
  }

  .interactive-item {
    max-width: 100%; /* Asegurarse de que los ítems ocupen el ancho completo en móvil */
    padding: 15px;
  }

  .interactive-icon {
    width: 60px; /* Reducir el tamaño del ícono */
    height: 60px;
  }

  .interactive-text h3 {
    font-size: 1.2rem; /* Ajustar el tamaño del título */
  }

  .interactive-text p {
    font-size: 0.9rem; /* Ajustar el tamaño del texto */
  }
}
