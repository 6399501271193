.contact-form-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #ffffff;
  margin-top: 150px;
  margin-bottom: 150px;
  transform: translateY(20px);
  animation: fadeIn 0.5s ease-out forwards;
  font-family: "Open Sans", sans-serif; /* Aplica la misma fuente global */
}
  .form-group {
    margin-bottom: 15px;
  }
  
  label {
    display: block;
    margin-bottom: 5px;
  }
  
  input, select, textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-family: "Open Sans", sans-serif; /* Asegura que los campos usen la misma fuente */
    font-size: 16px; /* Tamaño de fuente uniforme */
}
textarea {
  resize: vertical; /* Solo permitir redimensionar verticalmente */
}
  
  
  .success-message {
    color: green;
  }
  
  .error-message {
    color: red;
  }
  