 
  .login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    margin-top: -80px;
  }
  
  .login-form {
    background: #fff;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    width: 300px;
  }
  
  .login-form h2 {
    margin-bottom: 20px;
    font-size: 24px;
    color: #333;
  }
  
  .login-form input {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .login-form .login-button {
    width: 100%;
    padding: 10px;
    background-color: #3498db;
    border: none;
    border-radius: 5px;
    color: white;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-bottom: 15px;
  }
  
  .login-form .login-button:hover {
    background-color: #2980b9;
  }
  
  .login-form .register-button {
    width: 100%;
    color: rgb(0, 0, 0);
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .login-form .register-button:hover {
    color: #000000a2;
  }
  @media (max-width: 480px) {
    .login-form {
        padding: 30px; /* Reducimos el padding para ahorrar espacio en móviles */
        border-radius: 8px;
    }

    .login-form h2 {
        font-size: 20px; /* Tamaño de texto más pequeño */
    }

    .login-form input {
        padding: 8px; /* Ajustamos el padding de los inputs */
        font-size: 14px; /* Reducimos el tamaño de fuente */
    }

    .login-form .login-button, .login-form .register-button {
        font-size: 14px; /* Reducimos el tamaño del texto de los botones */
        padding: 8px; /* Ajustamos el tamaño de los botones */
    }
}