.verify-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100vh;
    text-align: center;
}

h2 {
    font-size: 24px;
    margin-bottom: 10px;
}

p {
    font-size: 18px;
    color: #555;
}

.loading-spinner {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-left-color: #4caf50;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
    margin-bottom: 15px;
}


.verify-error h2 {
    color: #f44336;
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}
