/* styles/Platform/Apps.css */

.fothz-assist-apps {
    padding: 0px;
    font-family: 'Open Sans', sans-serif;
    color: #333;
    margin-top: 150px;
    margin-left: 1px;
    background-image: url('../effects/graphics/fireworks_02.svg');
    background-repeat: no-repeat;
    background-position: top right;
    background-size: 15%, 15%;
    opacity: 0;
    transform: translateY(20px);
    animation: fadeIn 1s ease-out forwards;
  }
.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 15px;
    text-align: center;
    opacity: 0;
    transform: translateY(20px);
    animation: fadeIn 1s ease-out forwards;
    margin-bottom: 200px;
  }
  .fothz-assist-apps-header h1 {
    font-family: "Caveat", cursive;
    font-size: 80px;
    margin-bottom: 1rem;
    color: #333;
    background-image: url('../effects/highlights/yellow_highlight_03.svg');
    background-repeat: no-repeat;
    background-position: center;
    text-align: center;
  }
  .fothz-assist-apps-header h2 {
    font-family: "Caveat", cursive;
    font-size: 60px;
    margin-bottom: 1rem;
    color: #333;
    text-align: center;
  }

  .o_colored_level {
    padding: 4rem 0; /* Espaciado en la parte superior e inferior */
  }
  .row {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
  
  .x_wd_app_entry {
    flex: 0 1 20%; /* Cambia el porcentaje según cuántos quieras por fila */
    max-width: 150px;
    margin: 10px;
    text-align: center;
  }
  
  .x_wd_app_entry figure {
    margin: 0;
    text-align: center;
  }
  
  .x_wd_app_entry img {
    width: 80px;
    height: 80px;
    margin-bottom: 15px;
    object-fit: cover;
    transition: transform 0.3s ease;
  }
  
  .x_wd_app_entry:hover img {
    transform: rotate(10deg);
  }
  
  .x_wd_app_entry figcaption {
    font-size: 0.9rem;
    color: #555;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-top: 10px;
  }
  figcaption {
    font-size: 14px;
    color: #555;
    white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
  }
  .text-center {
    text-align: center;
  }
  
  .text-o-color-5 {
    color: #6c757d; /* Color secundario */
  }
  
  .mb-3 {
    margin-bottom: 1rem;
  }
  
  .mt-5, .mt-md-6 {
    margin-top: 3rem;
  }
  
  .p-0 {
    padding: 0;
  }
  
  .img-thumbnail {
    width: 80px;
    height: 80px;
  }
  
  @media (max-width: 1024px)  {
    .x_wd_app_entry {
      flex: 0 1 45%;
    }
  }
  
  @media (max-width: 480px) {
    .x_wd_app_entry {
      flex: 0 1 90%;
    }
  }
  @keyframes fadeIn {
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @media (max-width: 1024px)  {
    .fothz-assist-apps {
      margin-top: 120px;
      background-size: 15%; /* Ajustar el tamaño del gráfico SVG */
    }
  
    .container {
      padding: 0 10px;
      margin-bottom: 150px;
    }
  
    .fothz-assist-apps-header h1 {
      font-size: 50px;
      background-size: 100%; /* Ajustar el tamaño del highlight */
    }
  
    .fothz-assist-apps-header h2 {
      font-size: 40px;
    }
  
    .x_wd_app_entry {
      flex: 0 1 45%; /* Ajustar el tamaño de las entradas de la app para pantallas más pequeñas */
      max-width: 120px;
      margin: 5px;
    }
  
    .x_wd_app_entry img {
      width: 60px;
      height: 60px;
    }
  
    figcaption {
      font-size: 12px;
    }
  }
  
  @media (max-width: 480px) {
    .fothz-assist-apps-header h1 {
      font-size: 40px;
    }
  
    .fothz-assist-apps-header h2 {
      font-size: 30px;
    }
  
    .x_wd_app_entry {
      flex: 0 1 90%;
      max-width: 100px;
    }
  
    .x_wd_app_entry img {
      width: 50px;
      height: 50px;
    }
  
    figcaption {
      font-size: 10px;
    }
  }
  