.main-service {
  font-family: "Open Sans", sans-serif;
  padding: 60px 100px;
  background-color: #FFFBF4;
  background-image: url('../effects/graphics/corner_4.svg'); /* Añade la ruta del SVG */
  background-repeat: no-repeat;
  background-position: top left;
  background-size: 15%;
}


.stage {
  padding: 60px 0;
  text-align: center;
  border-bottom: 1px solid #e0e0e0;
  border-radius: 10px;
  margin-top: 10px;
}

.stage-intro {
  border-bottom: 0px;
  margin-top: 100px;
}

.stage h2 {
  font-family: "Caveat", cursive;
  background-image: url('../effects/highlights/blue_highlight_01.svg'); /* Añade la ruta del SVG */
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: contain; /* Asegura que el SVG se ajuste al ancho del texto */
  font-size: 60px;
  margin-bottom: 1.5rem;
}

.stage p {
  font-size: 1.2rem;
  color: #555;
}

.stage-feature {
  background-color: #FEF0D8;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: transform 0.3s ease-in-out;
}

.stage-feature.alt {
  background-color: #B1D8FC;
}

.feature-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 20px;
  max-width: 1600px;
  margin: 0 auto;
}

.feature-content .text {
  flex: 1;
  padding: 20px;
  text-align: center;
}

.feature-content .text h3 {
  font-size: 60px;
  margin-bottom: 1rem;
  font-family: "Caveat", cursive;
}

.feature-content .text p {
  font-size:25px;
  color: #2c2c2c;
}

.feature-content .media {
  flex: 1;
  display: flex;
  justify-content: center;
  margin: 10px;
}

.feature-content .media img {
  max-width: 100%;

}

.stage-summary {
  color: #222222;
  border-bottom: 0px;
}

.stage-summary a {
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  background: #222222;
  color: white;
  cursor: pointer;
  transition: background 0.3s, color 0.3s;
  font-size: 1.2rem;
  font-weight: bold;
}

.stage-summary a:hover {
  background: rgb(59, 59, 59);
  color: white;
}

@media (max-width: 1024px)  {
  .feature-content {
    flex-direction: column;
    text-align: center;
  }

  .feature-content .text {
    text-align: center;
  }
}

@media (max-width: 1024px)  {
  .main-service {
    padding: 30px 20px;
    background-size: 40%; /* Aumenta el tamaño del SVG para ser más visible en móviles */
    background-position: top left; /* Mantén la posición del SVG en la esquina */
  }

  .stage {
    padding: 30px 0;
  }

  .stage-intro {
    margin-top: 50px;
  }

  .stage h2 {
    font-size: 40px;
    background-size: 30%; /* Ajusta el subrayado SVG para que cubra todo el ancho del título */
    background-position: bottom center; /* Asegura que el subrayado esté centrado */
    margin-top: 50px
  }

  .stage p {
    font-size: 1rem;
  }

  .feature-content {
    flex-direction: column;
    text-align: center;
    padding: 0 15px; /* Ajusta el padding para pantallas más pequeñas */
  }

  .feature-content .text h3 {
    font-size: 40px;
  }

  .feature-content .text p {
    font-size: 18px;
  }

  .feature-content .media {
    margin: 20px 0; /* Aumenta el espacio entre el texto y la imagen */
  }

  .feature-content .media img {
    width: 80%; /* Reduce el tamaño de la imagen en pantallas pequeñas */
  }
}
