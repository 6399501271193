.header {
  position: fixed;
  top: 20px; /* Separa el header de la parte superior */
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% - 80px); /* Deja espacio a los lados */
  max-width: 1400px; /* Limita el ancho del header */
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 40px;
  background: white;
  color: #1b1b1b;
  box-shadow: 0 4px 2px -2px gray;
  border-radius: 20px; /* Esquinas redondeadas */
  z-index: 1000;
  font-family: "Open Sans", sans-serif;
  transition: background 0.3s, color 0.3s;
  font-size: 12px;
}

.logo img {
  height: 40px;
  transition: opacity 0.3s;
}

nav {
  display: flex;
  align-items: center;
  justify-content: left;
  flex-grow: 1;
  margin-left: 50px;
}

nav a {
  position: relative;
  margin: 0 15px;
  text-decoration: none;
  color: inherit;
  font-weight: bold;
}

nav a::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -5px;
  width: 100%;
  height: 2px;
  background: currentColor;
  transform: scaleX(0);
  transition: transform 0.3s ease;
}

nav a:hover::after {
  transform: scaleX(1);
}

.header-buttons {
  display: flex;
  align-items: center;
}

.contact-whatsapp-button {
  padding: 10px 20px;
  border: none;
  background-color: #25D366;
  color: white;
  font-size: 12px;
  cursor: pointer;
  transition: background-color 0.3s;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  margin-right: 15px;
  font-family: "Open Sans", sans-serif;
  font-weight: bold;
  text-decoration: none; 
}

.contact-whatsapp-button:hover {
  background-color: #1EBEA5;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.contact-demo-button {
  padding: 10px 20px;
  border: none;
  background-color: #256ad3;
  color: white;
  font-size: 12px;
  cursor: pointer;
  transition: background-color 0.3s;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  margin-right: 15px;
  font-family: "Open Sans", sans-serif;
  font-weight: bold;
  text-decoration: none;
}

.contact-demo-button:hover {
  background-color: #4d86db;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.login-button {
  padding: 10px 20px;
  border: 2px solid #1b1b1b;
  border-radius: 8px;
  background: transparent;
  color: #1b1b1b;
  cursor: pointer;
  transition: background 0.3s, color 0.3s;
  font-family: "Open Sans", sans-serif;
  text-decoration: none;
}

.login-button:hover {
  background: #1b1b1b;
  color: white;
}

.dropdown {
  position: relative;
}

.dropbtn {
  background: none;
  color: inherit;
  border: none;
  cursor: pointer;
  font-size: 12px;
  font-family: inherit;
  margin: 0 15px;
  font-weight: bold;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: rgb(255, 255, 255);
  min-width: 240px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  color: black;
  top: calc(100% + 1px);
  left: 0;
  border-radius: 8px;
  padding: 25px;
}

.dropdown-content a {
  color: black;
  padding: 12px 8px;
  text-decoration: none;
  display: block;
  text-align: left; /* Added this line to align the text to the left */
}

.dropdown-content a:hover {
  background-color: #ddd;
  border-radius: 8px;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown .dropdown-content .dropdown {
  position: relative;
}

.dropdown .dropdown-content .dropdown .dropdown-content {
  display: none;
  top: 0;
  left: 100%;
  margin-top: -20px;
  margin-left: 1px;
}

.dropdown .dropdown-content .dropdown:hover .dropdown-content {
  display: block;
}

.sub-dropbtn {
  background: none;
  color: inherit;
  border: none;
  cursor: pointer;
  font-size: 12px;
  font-family: inherit;
  text-align: left;
  width: 100%;
  font-weight: bold;
  margin: 5px;
  padding: 12px 12px;
}

.sub-dropbtn:hover {
  background-color: #ddd;
  border-radius: 8px;
}
.user-menu {
  position: relative;
  display: inline-block;
}

.user-menu button {
  background: none;
  border: none;
  cursor: pointer;
  font-weight: bold;
}

.dropdown-content {
  display: none;
  position: absolute;
  right: 100;
  background-color: white;
  box-shadow: 0px 8px 16px rgba(0,0,0,0.2);
  z-index: 1;
  font-weight: bold;
}

.user-menu:hover .dropdown-content {
  display: block;
}

.dropdown-content a, .dropdown-content button {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  background: none;
  border: none;
  width: 100%;
  text-align: left;
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
}

.dropdown-content a:hover, .dropdown-content button:hover {
  background-color: #f1f1f1;
}
.icon-comingsoon {
  width: 24px;
  height: 24px;
}
.mobile-menu-icon {
  display: none;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.nav {
  display: flex;
  align-items: center;
}

.nav.open {
  display: none;
}
@media (max-width: 1024px)  {
  .header {
    display: none; /* Oculta el header de escritorio */
  }
}
