.mobile-header {
    background-color: #fff;
    padding: 30px;
    z-index: 10000000;
    position: fixed;
    top: 0;
    width: 100%;
    font-family: "Open Sans", sans-serif;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Agregar sombra */
}

.mobile-header-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
  .mobile-logo img {
    width: 100px;
  }
  
  .menu-toggle {
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
  }
  
  .mobile-menu {
    background-color: #ffffff;
    padding: 15px;
    width: 100%;
    height: 100vh; /* Altura completa de la ventana */
    flex-direction: column;
    align-items: flex-start; /* Coloca los elementos en la parte superior */
    
  }
  
  .mobile-menu ul {
    list-style: none;
    padding: 0;
  }
  
  .mobile-menu li {
    margin-bottom: 10px;
    
  }
  
  .mobile-menu li button {
    background: none;
    border: none;
    font-size: 18px;
    color: #333;
    cursor: pointer;
    padding: 10px;
    display: block;
    font-size: 16px;
    font-weight: 600;
  }
  
  .mobile-submenu {
    margin-left: 20px;
  }
  
  .mobile-submenu ul {
    list-style: none;
    padding: 0;
  }
  
.mobile-submenu li a {
    display: block;
    background-color: #ffffff;
    padding: 5px; 
    margin: 3px 0; 
    text-decoration: none;
    border-radius: 5px;
    font-family: "Open Sans", sans-serif;
    font-weight: 500;
    font-size: 14px;
}
  
  .mobile-buttons {
    margin-top: 20px;
  }
  
.mobile-contact-button,
.mobile-whatsapp-button {
    display: block;
    background-color: #222222;
    color: white;
    text-align: center;
    padding: 10px;
    margin-bottom: 10px;
    text-decoration: none;
    border-radius: 5px;
    position: fixed;
    bottom: 0;
    font-size: 15px;
    font-weight: 600;
    align-items: center;
    width: 50%;
}
  
  .mobile-whatsapp-button {
    background-color: #25d366;
  }
  
  .mobile-user-menu {
    margin-top: 20px;
  }
  
  .mobile-user-dropdown {
    display: flex;
    flex-direction: column;
  }
  
  .mobile-user-dropdown a,
  .mobile-user-dropdown button {
    background-color: #e0e0e0;
    padding: 10px;
    margin: 5px 0;
    text-decoration: none;
    text-align: left;
    color: #333;
  }
  
  .mobile-login-button {    
    color: rgb(0, 0, 0);    
    display: block;
    text-align: start;
    text-decoration: none;    
    margin-left: 10px;
  }
  
  /* Ocultar el header móvil en pantallas grandes */
@media (min-width: 1024px) {
    .mobile-header {
      display: none; /* Oculta el header móvil */
    }
  }

.mobile-separator {
    width: 3000%;   
    height: 1px;
    background-color: #dadada;
    margin: 10px 0;
    margin-left: -1000%;
}