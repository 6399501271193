.services-section {
  padding: 60px 20px;
  background-color: #f4f4f4; /* Un fondo claro y limpio */
  text-align: center;
  font-family: "Open Sans", sans-serif;
  margin-top: -1px;
  background-image: url('../effects/graphics/fireworks_01.svg'); /* Añade la ruta del SVG */
  background-repeat: no-repeat;
  background-position: top left;
  background-size: 15%;
}

.services-section h2 {
  font-size: 40px;
  color: #222;
  display: inline-block;
  padding: 15px;
  animation: fade-in 1s ease-in-out;
  font-family: "Caveat", cursive;
  background-image: url('../effects/highlights/blue_highlight_01.svg'); /* Añade la ruta del SVG */
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: contain; /* Asegura que el SVG se ajuste al ancho del texto */
}


@keyframes fade-in {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.services-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  margin-top: 50px;
  margin-left: 100px;
  margin-right: 100px;
  animation: fade-in 1s ease-in-out;
}

.service {
  flex: 1 1 300px;
  max-width: 300px;
  background: #ffffff; /* Fondo blanco */
  padding: 20px;
  margin: 10px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
  position: relative;
  padding-bottom: 60px; /* Espacio adicional para el botón */
}

.service:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.service-icon {
  font-size: 3rem;
  color: #1bb6f9; /* Azul vibrante */
  margin-bottom: 1rem;
}

.service h3 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: #333333; /* Color más oscuro para mejor legibilidad */
}

.service p {
  font-size: 1rem;
  color: #666666; /* Color gris oscuro */
}

.service-button {
  display: inline-block;
  margin-top: 10px;
  padding: 10px 20px;
  background-color: #1bb6f9; /* Azul vibrante */
  color: white;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s;
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
}

.service {
  position: relative;
  padding-bottom: 60px; /* Espacio adicional para el botón */
}

.service-button:hover {
  background-color: #1bb6f98b; /* Azul más oscuro para el efecto hover */
}
@media (max-width: 1024px)  {
  .services-section {
    padding: 30px 10px;
    background-size: 30%;
    background-image: none; /* Eliminar el SVG en la versión móvil */
  }

  .services-section h2 {
    font-size: 30px;
    background-size: 100%;
  }

  .services-container {
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 30px;
    gap: 10px;
  }

  .service {
    max-width: 100%;
    padding: 15px;
    padding-bottom: 50px;
  }

  .service-icon {
    font-size: 2.5rem;
  }

  .service h3 {
    font-size: 1.2rem;
  }

  .service p {
    font-size: 0.9rem;
  }

  .service-button {
    padding: 8px 15px;
    bottom: 15px;
  }
  .services-section h2 {
    font-size: 40px;
    background-size: 30%; /* Ajusta el subrayado SVG para que cubra todo el ancho del título */
    background-position: bottom center; /* Asegura que el subrayado esté centrado */
    margin-top: 50px
  }

}
