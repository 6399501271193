.register-container {
  display: flex;
  justify-content: center;
  align-items: flex-start; /* Cambiamos a flex-start para que el contenido comience desde arriba */
  min-height: 100vh;
  padding: 20px; /* Agregamos padding para evitar que el contenido toque los bordes */
  overflow-y: auto; /* Permitimos el scroll vertical si es necesario */
  margin-top: 100px;
}

.register-form {
  background: #fff;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  width: 100%;
  max-width: 800px;
  margin-top: 50px; /* Ajustamos el margen superior */
}

.register-form h2 {
  margin-bottom: 20px;
  font-size: 24px;
  color: #333;
}

.register-form input {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.register-form .register-button {
  width: 100px;
  padding: 10px;
  background-color: #1b1b1b;
  border: none;
  border-radius: 5px;
  color: white;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.register-form .register-button:hover {
  background-color: #373737;
}
.register-form {
  position: relative;
}

.back {
  position: absolute;
  top: 10px;
  left: 10px;
  color: black;
  font-size: 16px;
  cursor: pointer;
  font-size: 12px;
}

.back:hover {
    color: #000000b0;
  }

  @media (max-width: 480px) {
    .register-form {
      padding: 30px;
      width: 100%;
      max-width: 350px;
      margin-top: 100px;
    }
  
    .register-form h2 {
      font-size: 20px; /* Reducimos el tamaño de fuente del título */
    }
  
    .register-form input {
      padding: 8px;
      font-size: 14px; /* Tamaño de fuente más pequeño para inputs */
    }
  
    .register-form .register-button {
      width: 100%; /* El botón ocupa el 100% del ancho */
      padding: 8px;
      font-size: 14px; /* Reducimos el tamaño de fuente del botón */
    }
  
    .back {
      font-size: 12px; /* Reducimos el tamaño de la fuente del botón de regresar */
    }
  }
  