.global-platform-section {
  padding: 60px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  font-family: "Open Sans", sans-serif;
  margin-bottom: 150px;
  background-color: #d2e9ff;
  background-image: url('../effects/highlights/yellow_highlight_bold_05xl.svg'); /* Añade la ruta del SVG */
  background-repeat: no-repeat;
  background-position: center;
  background-size: 250%; /* Asegura que el SVG se ajuste al ancho del texto */
}

.global-platform-section .content {
  flex: 1;
  text-align: left;
  max-width: 600px;
  margin-left: 150px;
}

.global-platform-section h3 {
  font-size: 20px;
  color: #555;
  margin-bottom: 1rem;
}

.global-platform-section h2 {
  font-size: 50px;
  color: #333;
  margin-bottom: 1.5rem;
  font-family: "Caveat", cursive;

}

.global-platform-section p {
  font-size: 1.1rem;
  color: #555;
}

.global-platform-section .video-container {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.global-platform-section .video-content {
  max-width: 600px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
@media (max-width: 1024px)  {
  .global-platform-section {
    flex-direction: column;
    gap: 10px;
    margin-bottom: 80px;
    background-size: 4000%;
    padding: 30px 10px;
   
  }
  .mobile-hide {
    display: none;
  }

  .global-platform-section .content {
    text-align: center;
    margin-left: 0;
    max-width: 100%;
  }

  .global-platform-section h2 {
    font-size: 35px;
  }

  .global-platform-section h3 {
    font-size: 18px;
  }

  .global-platform-section p {
    font-size: 1rem;
  }

  .global-platform-section .video-container {
    max-width: 100%;
  }

  .global-platform-section .video-content {
    max-width: 300px; /* Reducir el tamaño del video */
  }
}
