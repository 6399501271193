/* src/styles/SectionSeparator.css */
.section-separator-red {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 0; /* Espacio alrededor del SVG */
    background-color: transparent; /* O el color de fondo que prefieras */
    margin-bottom: -200px;
  }
  
.separator-svg {
    display: block;
    width: 100%;
    height: auto;
    margin: 0 auto; /* Add this line to center the SVG horizontally */
}
  