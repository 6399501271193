/* Blog.css */

.blog-construction {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
    font-family: 'Open Sans', sans-serif;
    opacity: 0;
    transform: translateY(20px);
    animation: fadeIn 1s ease-out forwards;
  }
  
  .construction-icon {
    font-size: 5rem;
    color: #e67e22;
    margin-bottom: 20px;
  }
  
  .blog-construction h2 {
    font-size: 2rem;
    margin-bottom: 10px;
  }
  
  .blog-construction p {
    font-size: 1.2rem;
    color: #555;
  }
  