/* src/styles/Services/DevelopmentSoftware.css */

.development-software {
  padding: 0px;
  font-family: 'Open Sans', sans-serif;
  color: #333;
  margin-top: 150px;
  background-image: url('../effects/graphics/fireworks_01.svg'), url('../effects/graphics/fireworks_02.svg');
  background-repeat: no-repeat;
  background-position: top left, top right;
  background-size: 15%, 15%;
  opacity: 0;
  transform: translateY(20px);
  animation: fadeIn 1s ease-out forwards;
}

.development-software-header {
  text-align: center;
  margin-bottom: 40px;
  
}

.development-software-header h1 {
  font-family: "Caveat", cursive;
  font-size: 100px;
  margin-bottom: 1rem;
  color: #333;
  background-image: url('../effects/highlights/green_highlight_03.svg');
  background-repeat: no-repeat;
  background-position: center;
  margin-top: 100px;
}

.development-software-content {
  max-width: 1400px;
  margin: 0 auto;
  text-align: center;
  margin-bottom: 40px;
  margin-top: 100px;
}

.video-container {
  display: flex;
  justify-content: space-around;
  margin: 40px 0;
}

.video-wrapper {
  width: 80%;
  overflow: hidden;
  border-radius: 10px;
  
}
.img-container {
  display: flex;
  justify-content: space-around;
  margin: 40px 0;
}

.img-wrapper {
  width: 45%;
  overflow: hidden;
  border-radius: 10px;
  
}
.img-wrapper img {
  width: 100%;
  height: auto;
  border-radius: 10px;
}
.video-wrapper video {
  width: 100%;
  height: auto;
  border-radius: 10px;
}
@keyframes fadeIn {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 1024px)  {
  .development-software {
    margin-top: 100px;
    background-size: 20%, 20%; /* Ajuste del tamaño de los gráficos SVG */
  }

  .development-software-header h1 {
    font-size: 70px; /* Reducir el tamaño del título en pantallas pequeñas */
  }

  .development-software-content {
    max-width: 100%;
    padding: 0 20px; /* Agregar padding para ajustar el contenido */
  }

  .video-container, .img-container {
    flex-direction: column; /* Cambiar a columnas para pantallas pequeñas */
    align-items: center;
  }

  .video-wrapper, .img-wrapper {
    width: 100%; /* Asegurarse de que el contenedor ocupe todo el ancho disponible */
    margin-bottom: 20px; /* Agregar espacio entre los videos y las imágenes */
  }
}

@media (max-width: 1024px) {
  .development-software {
    margin-top: 150px;

  }

  .development-software-header h1 {
    font-size: 50px; /* Reducir aún más el tamaño del título en pantallas muy pequeñas */
  }

  .video-wrapper, .img-wrapper {
    width: 100%; /* Ocupan todo el ancho en pantallas muy pequeñas */
    margin-bottom: 10px;
  }

  .development-software-content {
    padding: 0 10px;
  }
}
