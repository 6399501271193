/* Estilos globales básicos */
body, html {
  font-family: "Open Sans", sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: #d2e9ff;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.highlight-yellow {
  background-image: url('./effects/highlights/yellow_highlight_bold_05.svg'); /* Añade la ruta del SVG */
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%; /* Asegura que el SVG se ajuste al ancho del texto */
}

.highlight-green {
  background-image: url('./effects/highlights/green_highlight_03.svg'); /* Añade la ruta del SVG */
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%; /* Asegura que el SVG se ajuste al ancho del texto */
}



/* Back to Top Button */
@import '../styles/BackToTopButton.css';

/* Estilo para la advertencia de móviles */


/* Mostrar la advertencia solo en dispositivos móviles */
/* Ocultar la advertencia en pantallas grandes (escritorio) */

/* Por defecto, el mensaje está oculto en pantallas grandes */
.mobile-warning {
  display: none;
}

/* Mostrar el mensaje en pantallas móviles */
@media (max-width: 1024px)  {
  .mobile-warning {
    display: flex !important; /* Asegurarse que esté visible */
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.979);
    color: white;
    font-size: 18px;
    justify-content: center;
    align-items: center;
    text-align: center;
    z-index: 9999;
    padding: 20px;
    opacity: 1; /* Asegura que sea visible */
    visibility: visible; /* Asegura que sea visible */
  }

  /* Oculta el contenido de la aplicación */
  .App {
    overflow: hidden;
  }
}
