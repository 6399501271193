/* src/styles/SectionSeparator.css */
.section-separator {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 0; /* Espacio alrededor del SVG */
    background-color: transparent; /* O el color de fondo que prefieras */
  }
  
.separator-svg {
    display: block;
    width: 100%;
    height: auto;
    margin: 0 auto; /* Add this line to center the SVG horizontally */
}
  